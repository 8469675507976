import React, { useState, useEffect, useContext } from "react";
import { Link } from "gatsby";
import { Form } from "react-bootstrap";
import Ads from "./ads";
import Loader from "../images/loader.gif";
import settings from "./settings.json";
import setCityAndState from "../helper/setCityAndState";
import { GlobalStateContext } from "../store/GlobalContextProvider";
const axios = require("axios");

export default function Category() {
  const [list, setList] = useState([]);
  const [featured, setFeatured] = useState([]);
  const [search, setSearch] = useState("");
  const [display, setDisplay] = useState(true);

  const [city_name, setCityName] = useState("");
  const [state_code, setStateCode] = useState("");
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const state = useContext(GlobalStateContext);
  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      ignore = true;
      getCategories();
      getFeaturedCategories();
    }
    setLat(localStorage.getItem("lat"));
    setLng(localStorage.getItem("lng"));
    setCityAndState(setCityName, setStateCode);
  }, [state?.locationToggle]);

  const getFeaturedCategories = () => {
    axios({
      url: settings.apiURL,
      method: "post",
      data: {
        query: `{
          getFeaturedCategories {
              icon
              id
              name
              slug
              subcategories {
                category_id
                id
                name
                slug
              }
            }
          }`,
      },
    })
      .then((res) => {
        setFeatured(res.data.data.getFeaturedCategories);
        return "success";
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const getCategories = () => {
    axios({
      url: settings.apiURL,
      method: "post",
      data: {
        query: `{
            getCategoriesWithBusiness {
              icon
              id
              name
              slug
              subcategories {
                category_id
                id
                name
                slug
              }
            }
          }`,
      },
    })
      .then((res) => {
        setList(res.data.data.getCategoriesWithBusiness);
        setDisplay(false);
        return "success";
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <div>
      <section className="categorie-banner">
        <h1
          style={{ paddingTop: `90px`, fontSize: "50px" }}
          className="text-center font-weight-bold mb-0"
        >
          Categories
        </h1>
      </section>
      {/* 
       CHANGED - ZoneId from 78 to 79 as banner ads are not refreshing for zone 78
      */}

      <Ads zoneid={78} />
      <section className="container categories category-section py-5 p-md-5 bg-white all-category-group">
        <h2 style={{ color: `#000000` }} className="section-head">
          Most Popular Categories
        </h2>
        <div className="row category-row">
          {featured.slice(0, 12).map((item) => {
            return (
              <Link
                style={{ textDecoration: `none` }}
                className="col-lg-2 col-md-4 col-6 category-type"
                to={
                  city_name !== "" &&
                  state_code !== "" 
                    ? lat !== "" && lng !== ""
                      ? "/c/" +
                        item.slug +
                        "/" +
                        city_name +
                        "-" +
                        state_code +
                        "?" +
                        lat +
                        "-" +
                        lng
                      : "/c/" + item.slug + "/" + city_name + "-" + state_code
                    : "/c/" + item.slug
                }
                onClick={(e) => {
                  localStorage.setItem("slug", e.target.innerText);
                }}
              >
                <img
                  src={item.icon}
                  alt="categories"
                  style={{ objectFit: `contain` }}
                />
                {item.name}
              </Link>
            );
          })}
        </div>
      </section>
      <section className="category-section-search">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-7 col-12">
              <h1
                style={{ color: `#000000` }}
                className="header-main-heading text-left"
              >
                All Categories
              </h1>
            </div>
            <div className="col-lg-4 col-md-5 col-12">
              <div className="">
                <Form
                  name="searchForm"
                  method="post"
                  aria-label="search"
                  onSubmit={(e) => e.preventDefault()}
                  autoComplete="off"
                >
                  <input
                    type="hidden"
                    name="form-name"
                    value="searchForm"
                    aria-label="searchForm"
                  />
                  <Form.Group>
                    <input
                      placeholder="Search categories by name"
                      style={{
                        backgroundColor: `transparent`,
                        border: `none`,
                        borderBottom: `solid 2px #005D9D`,
                        color: `#195D9D`,
                        cursor: `pointer`,
                        fontSize: `20px`,
                        padding: `5px`,
                        width: `100%`,
                        height: `calc(1.5em + .75rem + 22px)`,
                        textOverflow: `ellipsis`,
                      }}
                      value={search}
                      type="text"
                      name="search"
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                      list="searchname"
                    />
                  </Form.Group>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container faq">
        <div className="row faq-row">
          {display ? (
            <p className="loader-wrapper">
              <img
                alt="Loader"
                height="100px"
                width="100px"
                style={{ margin: 30 }}
                src={Loader}
              />
            </p>
          ) : (
            <p style={{ display: `none` }}>
              <img
                alt="Loader"
                height="100px"
                width="100px"
                style={{ margin: 30 }}
                src={Loader}
              />
            </p>
          )}
          {list
            .filter((l) => l?.name.toLowerCase().includes(search.toLowerCase()))
            .map((cat) => {
              return (
                <div className="col-md-4">
                  <div className="accordion" id="customer-accordion">
                    <div className="card">
                      <div
                        className="card-header main-category-head"
                        id={"headingOne-" + cat.id}
                      >
                        <h2 className="mb-0">
                          <button
                            className="btn btn-link collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target={"#collapse-" + cat.id}
                            aria-expanded="false"
                            aria-controls={"#collapse-" + cat.id}
                          >
                            {city_name !== "" && state_code !== "" ? (
                              <Link
                                to={
                                  ((lat !== "" + lng) !== "") !== ""
                                    ? "/c/" +
                                      cat.slug +
                                      "/" +
                                      city_name +
                                      "-" +
                                      state_code +
                                      "?" +
                                      lat +
                                      "-" +
                                      lng
                                    : "/c/" +
                                      cat.slug +
                                      "/" +
                                      city_name +
                                      "-" +
                                      state_code
                                }
                                onClick={(e) => {
                                  localStorage.setItem(
                                    "slug",
                                    e.target.innerText
                                  );
                                }}
                              >
                                {cat.name}
                              </Link>
                            ) : (
                              <Link
                                to={"/c/" + cat.slug}
                                onClick={(e) => {
                                  localStorage.setItem(
                                    "slug",
                                    e.target.innerText
                                  );
                                }}
                              >
                                {cat.name}
                              </Link>
                            )}

                            {cat.subcategories.length > 0 ? (
                              <i className="fa fa-chevron-right"></i>
                            ) : (
                              ""
                            )}
                          </button>
                        </h2>
                      </div>
                      {cat.subcategories.length > 0 && (
                        <div
                          id={"collapse-" + cat.id}
                          className="collapse"
                          aria-labelledby="headingOne"
                          data-parent="#customer-accordion"
                        >
                          <div className="card-body">
                            <ul>
                              {cat.subcategories.map((subcat) => {
                                return (
                                  <li
                                    style={
                                      subcat.name !== ""
                                        ? {}
                                        : { listStyleType: `none` }
                                    }
                                  >
                                    {city_name !== "" && state_code !== "" ? (
                                      <Link
                                        to={
                                          "/c/" +
                                          cat.slug +
                                          "/subcat/" +
                                          subcat.slug +
                                          "/" +
                                          city_name +
                                          "-" +
                                          state_code
                                        }
                                        onClick={(e) => {
                                          localStorage.setItem(
                                            "slug",
                                            e.target.innerText
                                          );
                                        }}
                                      >
                                        {subcat.name}
                                      </Link>
                                    ) : (
                                      <Link
                                        to={
                                          "/c/" +
                                          cat.slug +
                                          "/subcat/" +
                                          subcat.slug
                                        }
                                        onClick={(e) => {
                                          localStorage.setItem(
                                            "slug",
                                            e.target.innerText
                                          );
                                        }}
                                      >
                                        {subcat.name}
                                      </Link>
                                    )}
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </section>
    </div>
  );
}
