import React from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import Category from "../components/category";
import SeO from "../components/SEO";
import "bootstrap/dist/css/bootstrap.min.css";
import "../css/mystyle.css";
export default function category() {
  return (
    <div>
      <SeO
        title={`Categories`}
        defer={true}
        description={`This is list all categories and sub-categories listed with christian blue `}
      />
      <Header />
      <Category />
      <Footer />
    </div>
  );
}
